<template>
  <div class="pageContent orderSearch">
    <div class="container mt3 mb3">
      <div class="column">
      <h1>{{ getText('o001') }}</h1>
      <p class="mb0">{{ getText('o002') }}</p>
      </div>
      <div class="column is-6">
        <b-field>
          <b-input type="text" :class="[hilite('orderId'),'mt0']" v-model="orderId" :placeholder="getText('o003')" ref="orderNumber"></b-input>
        </b-field>
        <b-field>
          <b-input type="text" :class="hilite('postcode')" v-model="postcode" :placeholder="getText('o004')"></b-input>
        </b-field>
        <b-field>
          <b-field class="cbInput">
            <b-checkbox v-model="tcRead" :disabled="tcRead == true"/>
          </b-field>
          <span class="link" @click.stop="openTerms">{{ getText('o005') }}</span>
        </b-field>
      </div>
      <CaptchaControl v-if="pageText.length > 0" @done="captchaComplete" :disabled="captchaPassed == true" :text="pageText" :errLogged="errLogged" />
      <div class="column is-6">{{ getText('o010') }}</div>
      <div class="btnBar btnBarRight column">
        <div class="align-right">
          <p :class="setOpacity">{{ getText('o009') }}</p>
          <b-button class="button bg" @click.stop="checkForm" :disabled="buttonLocked" :key="checks">{{ getText('continue', true) }}</b-button>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import ErrorModal from "@/components/Modals/ErrorModal.vue";
import CaptchaControl from '@/components/CaptchaControl.vue';
import TermsConditions from "@/components/Modals/TermsConditions";

export default {
  name: 'OrderSearch',

  components: {
    CaptchaControl
  },

  data() {
    return {
      orderId: '',
      postcode: '',
      tcRead: false,
      captchaPassed: false,

      checks: 0,
      pageText: [],
      generalText: [],
      errLogged: false,
      isLoading: false,
      hasBeenUnlocked: false,
      hilites: [],
      additionalData: '',
    }
  },

  mounted()
  {
    this.focusInput();
    this.pageText = this.$tools.translatePage();
    this.generalText = this.$tools.translatePage('general');
    if(this.pageText == false || this.generalText == false)
      this.openErrorMessage('yellow267-'+this.$tools.getLocaleId());
  },

  computed:
  {
    buttonLocked()
    {
      let locked = this.orderId.length < 3 || this.postcode.length < 3 || this.tcRead == false || this.captchaPassed == false;
      return this.testForHiLites(locked);
    },
    setOpacity()
    {
      return this.hasBeenUnlocked==true && this.buttonLocked == true ? '' : 'hideThis';
    }

  },

  methods:
  {
    getText(ref, general = false)
    {
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.errLogged = true;
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },

    checkForm()
    {
      let orderData = '';

      let data = {
        orderId: this.orderId.trim(),
        postcode: this.postcode.trim()
      }
      this.isLoading = true;
      this.$api.post('/order_search.php',data)
      .then(response => {
        if (response.status == 200)
        {
          // break down the error - no point the customer contacting CS if they've just entered basic info incorrectly
          if(response.data.orders.message == 'Order not found' || response.data.orders.message == 'Order item not found')
          {
            this.openErrorMessage('err480');
          }
            else if (response.data.orders.message == 'Order too old')
          {
            this.openErrorMessage('err500');
          } 
            else if (response.data.orders.message == 'redirect to mp')
          {
            this.additionalData = response.data.orders.name;
            this.openErrorMessage('err520');
          } else {
            orderData = this.transformData(response.data.orders.data);
            if(orderData != '' || orderData != false)
              this.$emit('done', orderData);
          }
          this.isLoading = false;
        }
      }).catch(() => {
        this.openErrorMessage('pink938');
        this.isLoading = false;
      });
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        props: {
          errorCode: errorCode,
          additionalData: this.additionalData
        }
      });
    },

    captchaComplete()
    {
      this.checks ++;
      this.captchaPassed = true;
    },

    focusInput() {
      this.$refs.orderNumber.focus();
    },

    transformData(data)
    {
      if(data.items.length == 0)
      {
        let errorCode = 'green112';
        this.openErrorMessage(errorCode);
        return false;
      } else
      {
        let items = data.items;
        for(let item of items)
        {
          item['selected'] = 0;
          item['qtyToReturn'] = 0;
          item['reason'] = '';
        }
        return data;
      }
    },

    openTerms()
    {
      this.$buefy.modal.open({
            parent: this,
            component: TermsConditions,
            hasModalCard: true
        });
    },

    testForHiLites(locked)
    {
      if(this.hasBeenUnlocked == true && locked == true)
      {
        if(this.orderId.length < 3)
        {
          this.hilites.push('orderId');
        }
        else
        {
          let index = this.hilites.indexOf('orderId');
          if(index > -1)
            this.hilites.splice(index,1);
        }

        if(this.postcode.length < 3)
        {
          this.hilites.push('postcode');
        }
        else
        {
          let index = this.hilites.indexOf('postcode');
          if(index > -1)
            this.hilites.splice(index,1);
        }
      }

      if(this.hasBeenUnlocked == false && locked == false)
      {
        this.hasBeenUnlocked = true;
      }

      if(this.hasBeenUnlocked == true && locked == false)
      {
        this.hilites = [];
      }
      return locked;
    },

    hilite(field)
    {
      return this.hilites.length > 0 && this.hilites.indexOf(field) > -1 ? 'hilite error' : ''; 
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .align-right { text-align:right; }
  .hideThis { opacity: 0;}
</style>
